/** ------------------------------
    Mixins
    ------------------------------ */

/*
    Only place global mixins here.
    Component-specific mixins such as `button` should be in
    the relevant component file.
 */

@define-mixin base-margin $scale: 1 {
    margin-top: 0;
    margin-bottom: calc(var(--base-rhythm) * $scale);
}

/* Grid mixins are used for layouts that a similar to grids, but not a lazy .grid component */
@define-mixin grid-container {
    margin-left: calc(var(--grid-gutter) / -2);
    margin-right: calc(var(--grid-gutter) / -2);
}

@define-mixin grid-item {
    padding-left: calc(var(--grid-gutter) / 2);
    padding-right: calc(var(--grid-gutter) / 2);
}

@define-mixin button-reset {
    appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
}

@define-mixin absolute-cover {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@define-mixin link-cover {

    &::after {
        content: "";
        @mixin absolute-cover;
    }

}

@define-mixin line-clamp $number: 1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $number;
    -webkit-box-orient: vertical;
}
