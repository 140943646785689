/** ------------------------------
    Ads
    ------------------------------ */
[ad-slot] {
    position: relative;
    margin: 24px auto 12px;
    width: 100%;
    text-align: center;

    &::before {
        content: "Advertisement";
        position: absolute;
        top: -15px;
        left: 0;
        width: 100%;
        font: 10px/1 sans-serif;
        color: #999;
        letter-spacing: .04em;
        text-transform: uppercase;
        }

    & iframe {
        vertical-align: middle;
        }
    }

/* Banner */
[ad-slot="hybrid-banner"] {

    @media (--layout-no-sidebar) {
        display: none;
        margin-top: 10px;
        min-height: 100px;
        }

    @media (--layout-sidebar) {
        min-height: 90px;
        }

    }

/* In content */
.editor-content {

    & [ad-slot] {
        margin-top: calc(2rem + 12px);
        margin-bottom: 2rem;
        min-height: 250px;
        }

    & [ad-slot="content-video"] {
        display: block;
        min-height: auto;
        aspect-ratio: 16/9;
        max-width: 580px;
        }

}

/* Feed */
[ad-slot="feed"] {
    margin-top: calc(1.5rem + 12px);
    margin-bottom: 1.5rem;
    min-height: 100px;
    }

/* Sidebar */
[ad-slot="sidebar"] {
    min-height: 250px;
    }
