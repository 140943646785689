/** ------------------------------
    Base
    ------------------------------ */
@define-mixin button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 var(--btn-padding);
    min-width: 100px;
    height: var(--btn-height);
    border: 0;
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);
    color: var(--btn-default-color);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 1;
    cursor: pointer;

    &:is(:hover, :focus) {
        background-color: var(--brand-secondary);
        color: var(--btn-default-color);
        }
}

.btn {
    @mixin button;
    }
    .btn__icon {
        flex-shrink: 0;
        margin-left: .4em;
        height: 1.125em;
        fill: currentcolor;

        &.btn__icon--left {
            margin-left: 0;
            margin-right: .4em;
            }
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--lg {
    font-size: calc(var(--btn-font-size) * 1.5);
    }

.btn--sm {
    font-size: calc(var(--btn-font-size) * .875);
    }

.btn--block {
    width: 100%;
    max-width: 400px;
    }
