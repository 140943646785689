/** ------------------------------
    Site Footer
    ------------------------------ */
.site-footer {
    @mixin container;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-top: 1px solid #333;
    font-size: .85rem;
    text-align: center;
    }

    /* Brand block */
    .site-footer__brand {
        margin-bottom: 1rem;
        width: 200px;

        & > img {
            display: block;
            }
        }
