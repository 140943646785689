/** ------------------------------
    Actions
    ------------------------------ */
.actions {
    position: fixed;
    z-index: 9999;
    bottom: calc(52px + 1rem);
    right: 1rem;
    display: flex;
    flex-direction: column;

    @media (width < 500px) {
        right: 2px;
        }

    @media (width >= 728px) {
        bottom: calc(92px + 1rem);
        }

    @media (width >= 1440px) {
        right: calc((100% - 1440px) / 2);
        }

    & > * {
        margin-top: .5rem;
        }

    & > :is(a, button) {
        @mixin button-reset;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background-color: var(--brand-primary);
        border-radius: 50%;
        color: #000;
        font-family: var(--heading-font-family);
        font-size: 1rem;
        text-transform: uppercase;
        cursor: pointer;

        @media (width >= 600px) {
            width: 80px;
            height: 80px;
            }

        &:hover {
            background-color: #fff;
            box-shadow: 0 0 1.5em rgba(255, 255, 255, .7);
            }

        & > svg {
            width: 50%;
            height: 50%;
            fill: currentcolor;
            }
        }

    }

#random-btn {
    background-image: radial-gradient(yellow 20%, limegreen 50%);

    &:hover {
        background: #fff url('../img/who.png') no-repeat 50% 50%;
        background-size: 80% auto;

        & > svg {
            opacity: 0;
            }
        }
    }
