/** ------------------------------
    Facebook
    ------------------------------ */
[cite*="facebook.com"] {
    word-break: break-word;
    margin-left: 0;
    margin-right: 0;
    padding: 15px;
    max-width: 500px;
    border-radius: 2px;
    border: 1px solid rgb(207, 217, 222);
    background-color: #efefef;
    color: #444;

    & p {
        margin-bottom: 1em;
        }

    & > :last-child {
        margin-bottom: 0;
        font-size: 15px;
        color: rgb(110, 118, 125);
        }

    & a {
        color: rgb(27, 149, 224);
        background: none !important;

        &:is(:hover, :focus) {
            text-decoration: underline;
            outline: 0;
            }
        }
    }


blockquote.instagram-media {

    & * {
        white-space: initial !important;
        }

    & a {
        outline: 0 !important;
        background: none !important;
        }

    }
