/** ------------------------------
    Gooker Awards
    ------------------------------ */
.gooker-grid {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    margin: 2rem auto;

    @media (width >= 900px) {
        gap: var(--grid-gutter);
        }
    }
    .gooker-grid__item {
        text-align: center;

        & img {
            margin-bottom: -8%;
            }

        & a {
            text-decoration: none;
            }
        }
