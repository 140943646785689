/** ------------------------------
    Feature grid
    ------------------------------ */
.feature-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: calc(var(--grid-gutter) / 2);
    margin-bottom: 2rem;

    & > :nth-child(1) {
        grid-column: 1 / 3;
        }

    & > :nth-child(2) {
        grid-column: 1 / 2;
        }

    & > :nth-child(3) {
        grid-column: 2 / 3;
        }

    @media (width >= 640px) {
        gap: var(--grid-gutter);
        }
    }
