/** ------------------------------
    YouTube
    ------------------------------ */
.yt-lite {
    position: relative;
    cursor: pointer;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 68px;
        height: 48px;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
        background-color: transparent;
        background-image: url(data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>);
        }
    }
