/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }


/** ------------------------------
    Overflow
    ------------------------------ */
body {
    overflow-x: hidden;
    }


/** ------------------------------
    Containers
    ------------------------------ */
@define-mixin container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @mixin container-width;
    @mixin container-gutters;
}

@define-mixin container-width {
    max-width: var(--container-width);
}

@define-mixin container-width-md {
    max-width: calc(var(--container-width) * .8);
}

@define-mixin container-width-sm {
    max-width: calc(var(--container-width) * .6);
}

@define-mixin container-width-fluid {
    max-width: none;
}

@define-mixin container-gutters {
    padding: 0 calc(var(--container-gutter) / 2);

    @media (width < 480px) {
        padding: 0 10px;
        }
}

@define-mixin negative-gutters {
    margin-left: calc(var(--container-gutter) / -2);
    margin-right: calc(var(--container-gutter) / -2);

    @media (width < 480px) {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.container {
    @mixin container;
    }

.container--md {
    @mixin container-width-md;
    }

.container--sm {
    @mixin container-width-sm;
    }

.container--fluid {
    @mixin container-width-fluid;
    }

.is-contained {
    margin: auto;
    max-width: var(--container-width);
    }


/** ------------------------------
    Wrapper
    ------------------------------ */
.site-wrapper {
    @mixin container;

    & main {
        margin-top: 1rem;
        }
    }

@media (--layout-sidebar) {

    .site-wrapper {
        display: grid;
        grid-template-columns: minmax(300px, 1fr) minmax(0, 3fr);
        gap: calc(var(--grid-gutter) * 2);

        & main {
            margin-top: 2rem;
            }
        }

}
