/** ------------------------------
    Section colours
    ------------------------------ */
.type-induction {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-inductions);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-inductions);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-inductions);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-inductions);
        }

    & .induction-grid__item {

        &:is(:hover, :focus-within) {

            & img {
                border-color: var(--color-inductions);
                }

            }

        }

    }

.card--induction {

    & .masked--border::after {
        background-color: var(--color-inductions);
        }

    }


.type-sbt {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-sbt);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-sbt);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-sbt);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-sbt);
        }

    & .induction-grid__item {

        &:is(:hover, :focus-within) {

            & img {
                border-color: var(--color-sbt);
                }

            }

        }

    }

.card--sbt {

    & .masked--border::after {
        background-color: var(--color-sbt);
        }

    }


.type-youtube {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-icfyt);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-icfyt);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-icfyt);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-icfyt);
        }

    }

.card--youtube {

    & .masked--border::after {
        background-color: var(--color-icfyt);
        }

    }


.type-mailbag {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-mailbag);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-mailbag);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-mailbag);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-mailbag);
        }

    }

.card--mailbag {

    & .masked--border::after {
        background-color: var(--color-mailbag);
        }

    }


.type-headlies {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-headlies);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-headlies);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-headlies);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-headlies);
        }

    & .induction-grid__item {

        &:is(:hover, :focus-within) {

            & img {
                border-color: var(--color-headlies);
                }

            }

        }

    }

.card--headlies {

    & .masked--border::after {
        background-color: var(--color-headlies);
        }

    }


.type-squash {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-squash);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-squash);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-squash);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-squash);
        }

    & .induction-grid__item {

        &:is(:hover, :focus-within) {

            & img {
                border-color: var(--color-squash);
                }

            }

        }

    }

.card--squash {

    & .masked--border::after {
        background-color: var(--color-squash);
        }

    }


.type-jobber {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-jobber);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-jobber);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-jobber);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-jobber);
        }

    & .induction-grid__item {

        &:is(:hover, :focus-within) {

            & img {
                border-color: var(--color-jobber);
                }

            }

        }

    }

.card--jobber {

    & .masked--border::after {
        background-color: var(--color-jobber);
        }

    }


.type-from-the-pages-of {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-ftp);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-ftp);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-ftp);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-ftp);
        }

    }

.card--from-the-pages-of {

    & .masked--border::after {
        background-color: var(--color-ftp);
        }

    }


.type-art-is-war {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-artiswar);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-artiswar);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-artiswar);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-artiswar);
        }

    }

.card--art-is-war {

    & .masked--border::after {
        background-color: var(--color-artiswar);
        }

    }

.type-features {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-features);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-features);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-features);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-features);
        }

    }

.card--features {

    & .masked--border::after {
        background-color: var(--color-features);
        }

    }


.type-gooker {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-gooker);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-gooker);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-gooker);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-gooker);
        }

    }

.card--gooker {

    & .masked--border::after {
        background-color: var(--color-gooker);
        }

    }


.type-vince-russo {

    & :is(.section-hd__title, .article__title) {
        color: var(--color-russo);
        }

    & :is(.editor-content, .byline, .pager) a {
        color: var(--color-russo);
        }

    & .editor-content blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        border-color: var(--color-russo);
        }

    & :is(#share-btn, #comments-btn) {
        background-color: var(--color-russo);
        }

    }

.card--vince-russo {

    & .masked--border::after {
        background-color: var(--color-russo);
        }

    }
