/** ------------------------------
    Section colours
    ------------------------------ */
.banner-stack {
    margin: auto;
    max-width: 600px;
    }
    .banner-stack__item {

        &:not(:first-child) {
            margin-top: 1rem;
            }

        & a {
            display: block;

            &:is(:hover, :focus-within) {
                box-shadow:
                    0px 0px 1.1px rgba(255, 255, 255, 0.034),
                    0px 0px 2.7px rgba(255, 255, 255, 0.048),
                    0px 0px 5px rgba(255, 255, 255, 0.06),
                    0px 0px 8.9px rgba(255, 255, 255, 0.072),
                    0px 0px 16.7px rgba(255, 255, 255, 0.086),
                    0px 0px 40px rgba(255, 255, 255, 0.12);
                }
            }

        }
