/** ------------------------------
    Base
    ------------------------------ */
html {
    background: #000 url('../img/site-bg.jpg') no-repeat 50% 50%;
    background-attachment: fixed;
    background-size: cover;
    font: var(--base-font-weight) var(--base-font-size)/var(--base-line-height) var(--base-font-family);
    color: var(--base-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

::selection {
    background-color: blue;
    color: white;
    }


/** ------------------------------
    Links
    ------------------------------ */
a {
    color: currentcolor;
    text-decoration: underline;
    text-decoration-skip-ink: auto;
    text-decoration-width: 2px;

    &:hover {
        color: var(--brand-secondary);
        text-decoration-color: currentcolor;
        }
    }


/** ------------------------------
    Headings
    ------------------------------ */
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .75em;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    color: #fff;
    line-height: 1.3;
    }

.hd-xl {
    font-size: calc(1rem + 2.25vw);
    line-height: 1.15;

    @media (width < 640px) {
        font-size: 1.75rem;
        }

    @media (width > 1600px) {
        font-size: 3.25rem;
        }
    }

.hd-lg {
    font-size: 2rem;
    font-size: clamp(1.5rem, 8vw, 2rem);
    line-height: 1.15;
    }

.hd-md {
    font-size: 1.25rem;
    }

.hd-bd {
    font-size: 1rem;
    }

.hd-sm {
    font-size: .85rem;
    }

.hd-xs {
    font-size: .75rem;
    }

.hd-branded {
    font-family: 'Mushu';
    letter-spacing: -.02em;
    }

.lead {
    font-size: 2rem;
    }


/** ------------------------------
    Utilities
    ------------------------------ */
.muted {
    color: var(--brand-mid);
    }

.shout {
    text-transform: uppercase;
    letter-spacing: 0.03em;
    }

.small {
    font-size: .875em;
    }

.byline {
    margin: .5rem 0 .75rem;
    font-size: .875rem;

    & a {
        color: var(--brand-primary);
        font-weight: 500;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            }
        }
    }


/** ------------------------------
    Content
    ------------------------------ */
p {
    @mixin base-margin;
    }
