/** ------------------------------
    Card
    ------------------------------ */
.card {
    position: relative;

    &:is(:hover, :focus-within) {

        & a {
            outline: 0;
            text-decoration: underline;
            }
        }

    }
    .card__media {
        position: relative;
        margin-bottom: .5rem;
        }
    .card__body {
        font-size: .875rem;
        padding: 0 3%;

        & > :last-child {
            margin-bottom: 0;
            }
        }
        .card__title {
            margin-bottom: .25rem;
            max-width: 50ch;
            font-family: var(--font-serif);
            font-size: 1.125rem;
            line-height: 1.2;
            @mixin line-clamp 3;

            @media (width < 400px) {
                font-size: 1rem;
                }

            & a {
                color: #fff;
                text-decoration: none;

                /* Pseduo link covering entire card */
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    }
                }
            }


/** ------------------------------
    Modifiers
    ------------------------------ */
.card--horizontal {
    display: flex;
    align-items: flex-start;

    & .card__media {
        flex: 0 0 33%;
        margin-bottom: 0;
        max-width: 240px;
        }

    & .card__body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: .33rem;
        padding-left: calc(var(--grid-gutter) / 2);
        padding-right: 0;
        }
        & .card__meta {
            margin-top: auto;
            }
    }

.card--featured {

    & .card__title {
        font-size: 1.45rem;

        @media (width >= 700px) {
            font-size: 1.75rem;
            }
        }

    }


/** ------------------------------
    Card grid
    ------------------------------ */
.card-grid {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: calc(var(--grid-gutter) / 2);
    }


/** ------------------------------
    Card stack
    ------------------------------ */
.card-stack {
    }
    .card-stack__item {

        &:not(:first-child) {
            margin-top: 1rem;

            @media (width >= 640px) {
                margin-top: 1.25rem;
                }
            }

        }
