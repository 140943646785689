/** ------------------------------
    Poll
------------------------------ */
.poll {
    margin: 2rem 0;
    max-width: 500px;
    }
    .poll__title {
        margin-bottom: 1em;
        font-size: 1.25rem;
        text-align: center;
        }
    .poll__option {
        overflow: hidden;
        position: relative;
        display: block;
        margin-bottom: .75rem;
        border-radius: 1.5rem;
        background-color: rgba(0, 0, 0, .9);
        user-select: none;

        & > input {
            position: absolute;
            visibility: hidden;

            &:checked ~ .poll__option-label {
                border-color: var(--color-gooker);
                background-color: var(--color-gooker);
                }

            &:not(:checked) ~ .poll__option-label:is(:hover, :focus-within) {
                background-color: var(--brand-secondary);
                color: #000;
                cursor: pointer;
                }
            }

        }
        .poll__option-label {
            position: relative;
            z-index: 5;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            height: 3rem;
            border-radius: 1.5rem;
            border: 1px solid currentcolor;
            color: #fff;
            line-height: 1.2;
            transition: background-color .2s ease-out;

            @media (width <= 420px) {
                font-size: .925rem;
                }

            &[data-percent] {
                border-color: var(--color-gooker);
                color: rgba(255, 255, 255, .9);

                &::after {
                    content: attr(data-percent);
                    margin-left: auto;
                    padding-left: 1em;
                    font-size: .925rem;
                    }
                }
            }
        .poll__option-percent {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: var(--color-gooker);
            opacity: .8;
            }
    .poll__btn {
        @mixin button;
        width: 50%;
        max-width: 200px;
        }
