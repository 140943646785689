/** ------------------------------
    Lazysizes
    ------------------------------ */
img {

    &.lazyload:not([srcset]) {
        visibility: hidden;
        }

    &:is(.lazyload, .lazyloading) {
        opacity: 0 !important;
        }

    &.lazyloaded {
        transition: opacity .3s ease-in;
        }

    }
