/** ------------------------------
    Section heading
    ------------------------------ */
.section-hd {
    margin-bottom: 2rem;
    }
    .section-hd__title {
        margin-bottom: 1rem;
        font-family: var(--heading-font-family);
        color: var(--brand-primary);
        text-transform: uppercase;
        letter-spacing: .03em;

        &:not[class*="hd-"] {
            font-size: 1.25rem;
            }
        }
