/** ------------------------------
    Pager
    ------------------------------ */
.pager {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 1.5rem 0;

    & > * {
        flex: 1 0 auto;
        padding: .25rem 1rem;
        text-align: center;
        }

    & a {
        font-family: 'MarkerFelt';
        font-size: 1.5rem;
        text-transform: uppercase;
        text-decoration: none;

        &:hover {
            color: #fff !important;
            }
        }
    }
