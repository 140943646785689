/** ------------------------------
    Brand colours
    ------------------------------ */
.fg-brand-primary {
    color: var(--brand-primary) !important;
    }

.fg-brand-secondary {
    color: var(--brand-secondary) !important;
    }

.fg-brand-light {
    color: var(--brand-light) !important;
    }

.fg-brand-dark {
    color: var(--brand-dark) !important;
    }

.fg-brand-white {
    color: #fff !important;
    }

.fg-brand-black {
    color: #000 !important;
    }

.bg-brand-primary {
    background-color: var(--brand-primary) !important;
    }

.bg-brand-secondary {
    background-color: var(--brand-secondary) !important;
    }

.bg-brand-light {
    background-color: var(--brand-light) !important;
    }

.bg-brand-dark {
    background-color: var(--brand-dark) !important;
    }

.bg-brand-white {
    background-color: #fff !important;
    }

.bg-brand-black {
    background-color: #000 !important;
    }

.bg-brand-twitch {
    background-color: #9146ff !important;
    }

.bg-brand-facebook {
    background-color: #1877f2 !important;
    }

.bg-brand-twitter {
    background-color: #1da1f2 !important;
    }

.bg-brand-whatsapp {
    background-color: #25d366 !important;
    }


/** ------------------------------
    Alignments
    ------------------------------ */
@define-mixin center-block {
    margin-left: auto;
    margin-right: auto;
}

.center-block {
    @mixin center-block;
    }

.align-center {
    text-align: center !important;
    }

.align-left {
    text-align: left !important;
    }

.align-right {
    text-align: right !important;
    }


/** ------------------------------
    Layout
    ------------------------------ */
.no-margin {
    margin: 0 !important;
    }

.no-padding {
    padding: 0 !important;
    }

.no-border {
    border: 0 !important;
    }

.width-cap {
    @mixin center-block;
    max-width: 70ch;
    }


/** ------------------------------
    Visibility
    ------------------------------ */
.hidden {
    display: none;
    }

@define-mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.visually-hidden {
    @mixin visually-hidden;
    }


/** ------------------------------
    Skip link
    ------------------------------ */
.skip-link {
    display: block;
    padding: 1em;
    color: #000;
    outline: 0;
    background-color: var(--brand-primary);
    text-align: center;

    &:not(:focus) {
        @mixin visually-hidden;
        }

    }


/** ------------------------------
    Masked
    ------------------------------ */
.masked {
    background-color: #000;
    mask-image: url('../img/mask.png');
    mask-size: 100% 100%;
    }

.masked--border {
    position: relative;

    &::after {
        @mixin absolute-cover;
        content: "";
        mask-image: url('../img/mask-border.png');
        mask-size: 100% 100%;
        background-color: transparent;
        }

    }


/** ------------------------------
    NEW! icon
    ------------------------------ */
.is-new {
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 60%;
        height: 50px;
        min-width: 60px;
        min-height: 40px;
        background: url('../img/new.png') no-repeat 50% 50%;
        background-size: contain;
        transform: rotate(-12deg) translate(-25%, -40%);
        }
    }


/** ------------------------------
    Audio link
    ------------------------------ */
.audio-link {

    & svg {
        display: inline-block;
        margin-left: .25em;
        width: 1em;
        height: 1em;
        fill: currentcolor;
        }

    }

/** ------------------------------
    Section logos
    ------------------------------ */
.section-logo {
    position: absolute;
    bottom: 0;
    right: 10px;
    width: 36%;
    max-width: 240px;
    }
