/** ------------------------------
    Fonts
    ------------------------------ */

@font-face {
    font-family: 'Mushu';
    font-display: swap;
    font-weight: 400;
    src: url('../fonts/Mushu.woff2') format('woff2');
}

@font-face {
    font-family: 'MarkerFelt';
    font-display: swap;
    font-weight: 400;
    src: url('../fonts/MarkerFelt.woff2') format('woff2');
}

@font-face {
    font-family: 'PTSerif';
    font-display: swap;
    font-weight: 400;
    src: url('../fonts/PTSerif-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'PTSerif';
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/PTSerif-Italic.woff2') format('woff2');
}

@font-face {
    font-family: 'PTSerif';
    font-display: swap;
    font-weight: 700;
    src: url('../fonts/PTSerif-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'PTSerif';
    font-display: swap;
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/PTSerif-BoldItalic.woff2') format('woff2');
}
