/** ------------------------------
    Tweets
    ------------------------------ */
.twitter-tweet:not(.twitter-tweet-rendered) {
    word-break: break-word;
    padding: 15px;
    max-width: 500px;
    border-radius: 15px;
    border: 1px solid #425364;
    background-color: #15202b;
    font: 500 19px/1.3125 system-ui;
    color: #fff;

    & p {
        margin-bottom: 1em;
        }

    & > :last-child {
        margin-bottom: 0;
        font-size: 15px;
        color: #536371;
        }

    & a {
        color: #026fd6;
        background: none !important;

        &:is(:hover, :focus) {
            text-decoration: underline;
            outline: 0;
            }
        }
    }
