/** ------------------------------
    Social List
    ------------------------------ */
.social-list {
    display: flex;
    align-items: center;
    line-height: 1;
    }
    .social-list__item {
        flex-shrink: 0;

        &:not(:first-child) {
            margin-left: 1em;
            }

        & :is(a, span) {
            color: #fff;
            cursor: pointer;

            &:hover {
                color: var(--brand-secondary);
                }
            }

        & svg {
            width: 1.5rem;
            height: 1.5rem;
            }
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.social-list--spaced {
    justify-content: space-between;

    & .social-list__item {
        margin: 0;
        }
    }

.social-list--center {
    justify-content: center;
    }


/** ------------------------------
    Wrapper
    ------------------------------ */
.social-wrapper {
    margin: 2rem 0;
    padding: 2rem;
    background-color: var(--brand-dark);

    & a {
        color: var(--brand-mid);

        &:hover {
            color: var(--brand-primary);
            }
        }
    }
