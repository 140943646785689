/** ------------------------------
    Share
    ------------------------------ */
.share {
    margin: 1rem 0;
    }
    .share__title {
        margin-bottom: .5rem;
        font-size: .825rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: .05em;
        }


/** ------------------------------
    Share list
    ------------------------------ */
.share-list {
    display: flex;
    }
    .share-list__item {
        flex: 1;

        @media (width < 500px) {

            &:nth-child(3) {
                display: none;
                }

            }

        &:not(:first-child) {
            margin-left: .75rem;
            }

        & .btn {
            width: 100%;
            }

        & svg {
            width: 1.125em;
            height: 1.125em;
            }
        }
