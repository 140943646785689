/** ------------------------------
    Inductions
    ------------------------------ */
.induction-grid {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(115px, 1fr));
    margin: 2rem auto;
    max-width: 800px;

    @media (width >= 540px) {
        gap: calc(var(--grid-gutter) / 2);
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        }

    @media (width >= 1100px) {
        gap: var(--grid-gutter);
        grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
    .induction-grid__item {
        text-align: center;

        & a {
            text-decoration: none;
            }

        & img {
            border: 2px solid transparent;
            }

        &:is(:hover, :focus-within) {

            & img {
                border-color: #fff;
                }

            }
        }
