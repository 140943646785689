/** ------------------------------
    Editor content
    ------------------------------ */
#tinymce.wp-editor,
.editor-content {

    & a:hover {
        text-decoration-style: dotted;
        opacity: .8;
        }

    & > h2 {
        font-size: 1.5rem;
        }

    & > :is(h3, h4, h5, h6) {
        font-size: 1.125em;
        }

    & > :is(ol, ul) {
        @mixin base-margin;
        padding-left: 2.5ch;
        }

    & > ol {
        list-style: decimal;
        }

    & > ul {
        list-style: disc;
        }

    & blockquote:not(.twitter-tweet, .tiktok-embed, .instagram-media, [cite*="facebook.com"]) {
        margin: 2rem 0;
        padding-left: 1.5em;
        border-left: 4px solid var(--brand-primary);
        font-family: var(--font-serif);
        line-height: 1.7;

        @media (width <= 600px) {
            padding-left: 1.25em;
            padding-right: .25em;
            }

        & > :last-child {
            margin-bottom: 0;
            }
        }

    & img {
        display: block;
        margin: 1.5rem auto;
        max-width: 100%;
        height: auto;
        }

    & .wp-caption {

        & img {
            margin: 0;
            }

        }

    & .wp-caption-text {
        padding: .5em;
        background-color: var(--brand-dark);
        font-size: .75rem;
        line-height: 1.4;
        }

    & > :is(figure, .responsive-media, .twitter-tweet, .instagram-media, .wp-embedded-content, [cite*="facebook.com"], .fb_iframe_widget, [src*="facebook.com/plugins"], .imgur-embed-iframe-pub) {
        margin: 2rem auto !important;
        min-width: auto !important;
        max-width: 100% !important;

        &:first-child {
            margin-top: 0 !important;
            }
        }

    & :is(.fb_iframe_widget) {
        display: block !important;
        max-width: 690px !important;
        background-color: #fff;
        }

    & :is(.twitter-tweet, .instagram-media, .fb-post) {
        max-width: 500px !important;
        }

    & video {

        &:focus {
            outline: 0;
            }

        }

    & :is(.poll) {
        margin: 2.5rem auto !important;
        min-width: auto !important;
        max-width: 540px !important;
        }

    & table {
        margin: 2rem auto;
        max-width: 100%;
        border: 0;

        & :is(th, td) {
            padding: .25em .5em;
            border: 0;
            vertical-align: top;

            &:not([align]) {
                text-align: left;
                }
            }
        }

    & .table-responsive {
        overflow-x: auto;
        margin: 2rem 0;
        width: 100%;

        & > table {
            margin: 0;
            }
        }

    & hr {
        margin: 2rem auto;
        border-width: 0;
        border-bottom: var(--rule-width) solid var(--rule-color);
        }

    }
