/** ------------------------------
    Article
    ------------------------------ */
@custom-media --article-stacked (width <= 860px);
@custom-media --article-sidebar (width > 860px);

.article {
    /* @mixin container; */
    margin-top: 1rem;
    margin-bottom: 3rem;
    }
    .article__header {
        margin-bottom: 1rem;
        }
        .article__title {
            margin: .5rem 0 3rem;
            max-width: 60ch;
            }
        .article__meta {
            margin-top: .5rem;
            }
            .article__meta-title {
                margin-bottom: 0;
                font-family: 'MarkerFelt';
                }
    .article__featured-media {
        @mixin negative-gutters;
        position: relative;
        margin-bottom: 1rem;

        & img {
            display: block;
            margin: auto;
            min-width: 60%;
            }
        }
    .article__content {
        margin: 0 auto 3rem;
        max-width: 70ch;
        line-height: 1.7;

        & .editor-content {
            margin: 2rem 0;
            }
        }

@media (--content-no-sidebar) {

    .article__sidebar {
        margin-top: 2rem;
        }

    .article__featured-media {
        @mixin negative-gutters;
        }

}

@media (--content-sidebar) {

    .article__content {
        padding: 0;
        }

}


/** ------------------------------
    Infinite articles
    ------------------------------ */
.infinite-articles {
    display: flex;
    flex-direction: column;

    & .article {
        margin-top: 0;
        margin-bottom: 0;
        }
    }
