/** ------------------------------
    Order
    ------------------------------ */
.orderby {
    display: flex;
    justify-content: center;
    }
    .orderby__item {
        margin: 0 .75em;

        & :is(a:hover, a:focus-within) {
            color: var(--brand-secondary);
            }
        }
